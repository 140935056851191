// Template for markdown layout
// See: https://www.gatsbyjs.com/docs/how-to/routing/adding-markdown-pages/
// Markdown files (contents) are under src/markdown-pages
// Images for markdown pages MUST be under src/markdown-pages for them to display correctly

import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import { ArrowLeft } from 'react-feather'

import "../styles/casestudy.css";



export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  const featuredImage = getImage(frontmatter.featuredImage)


  return (
    <Layout children={
      <article className="casestudy">

        {/* Start Header/hero section */}
        <div className="casestudy-header">
          <div className="container mt-5 pt-5">
            <div className="row d-flex align-items-center">
              <div className="col-md-6 mb-4 mb-md-0">
                <h1>{frontmatter.title}</h1>
                <p className="lead">{frontmatter.subtitle}</p>
              </div>
              <div className="col-md-6 mb-0 text-center text-lg-right">
                <GatsbyImage image={featuredImage} alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* End Header/hero section */}
        <div
          className="my-2 my-lg-5 py-3 container container-article"
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <div className="container container-article my-5 pb-4 text-center">
          <Link to="/" className="btn btn-outline-primary"><ArrowLeft />Back Home</Link>
        </div>
      </article>
    } />
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
          markdownRemark(frontmatter: {slug: {eq: $slug } }) {
          html
      frontmatter {
          date(formatString: "MMMM DD, YYYY")
        slug
        title
        subtitle
        featuredImage { 
           childImageSharp {
            gatsbyImageData(
              height: 500
              placeholder: BLURRED
              )
          }
        }
      }
    }
  }
`